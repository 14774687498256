import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/accordion/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/autocomplete/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/avatar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/badge/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/breadcrumbs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/button/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/calendar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/card/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/checkbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/chip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/date-input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/date-picker/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/dropdown/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ResizablePanel"] */ "/vercel/path0/node_modules/@nextui-org/framer-utils/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/image/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/link/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/listbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/modal/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/navbar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/pagination/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/popover/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/progress/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/radio/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/ripple/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/scroll-shadow/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/select/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/skeleton/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/slider/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/snippet/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/switch/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/system/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/table/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/tabs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/tooltip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/user/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 109.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 111.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 112.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 113.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 114.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 79.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 80.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 81.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 82.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 85.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 87.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 88.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 89.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 90.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 91.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 92.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 95.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 96.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/logodatxanh.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/HomePageMenu/index.tsx");
