const LandingPageRoutes = {
  home: "/home",
  products: {
    callbot: "/home/products/callbot",
  },
  landingPage: {
    callbot: "/landing-page/callbot",
    crm: "/landing-page/crm",
  },
  pricing: "/home/pricing",
};

export default LandingPageRoutes;
